import React, { Component } from 'react';
import connect from "react-redux/es/connect/connect";
import { onCellActionTrigger } from "../../actions/actions";
import client from '../../actions/client';
import showAToast from '../../components/common/showAToast';
import Translate from '../common/translate';

class CustomCellRenderer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            keys: {}
        };

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.generateRoute = this.generateRoute.bind(this);
        this.onCellActionTrigger = this.onCellActionTrigger.bind(this);
        this.getChildsFromList = this.getChildsFromList.bind(this);
        this.openCellMenu = this.openCellMenu.bind(this);
        this.handleActionClick = this.handleActionClick.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
        this.openPopup = this.openPopup.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({keys: {}});
        }
    }

    handleActionClick(url) {
        client
        .post(url)
        .then(response => response.data)
        .then(response => {
            if ('errors' in response) {
                showAToast(response.errors, 'baseError', 'error')
            }
            if ('success' in response) {
                showAToast(response.success, 'baseSuccess', 'success')
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    generateRoute(link) {
        if (!('params' in link)) {
            return link.url;
        }

        var url = link.url;

        if ('sourceParams' in link.params || 'rowParams' in link.params) {
            url = link.url;

            if ('sourceParams' in link.params) {
                link.params.sourceParams.map((param) => {
                    url = url.replace('{' + param.target + '}', param.source);
                });
            }

            if ('rowParams' in link.params) {
                link.params.rowParams.map((param) => {
                    url = url.replace('{' + param.target + '}', this.props[param.source]);
                });
            }

            return url;
        }

        link.params.map(param => {
            if (param.source in this.props || param.source in this.props.data) {
                if('inProps' in param){
                    url = url.replace('{' + param.target + '}', this.props.data[param.source]);
                }
                else {
                    url = url.replace('{' + param.target + '}', this.props[param.source]);
                }
            }
        });
        if(link.token){
            url = url        
        }

        return url;
    }

    getChildsFromList(list) {
        var source = this.props[list.source];
      
        var result = [];
        if (!('params' in list.link)) {           
            return list.link.url;
        }
       
        source.map((item, key) => {            
            var url = item.url ? item.url : list.link.url;

            if ('sourceParams' in list.link.params) {
                list.link.params.sourceParams.map((param) => {
                    url = url.replace('{' + param.target + '}', item[param.source]);
                });
            }

            if ('rowParams' in list.link.params) {
                list.link.params.rowParams.map((param) => {
                    url = url.replace('{' + param.target + '}', this.props[param.source]);
                });
            }
            if(list.link.token && !item.edit){
                url = url;
            }
            
   
            if('redirect' in list.link && list.link.redirect === false){
                result.push(<li className="submenus-item" key={key} >
                    <span style={{fontSize: 14, paddingLeft: 30}} onClick={() => this.handleActionClick(url)} target={list.link.target ? list.link.target : ''} className="submenus-link">
                        {item[list['labelSource']]}
                    </span>
                </li>);
            }else{
                result.push(<li className="submenus-item" key={key} >
                    <a href={url} style={{fontSize: 14, paddingLeft: 30}} target={list.link.target ? list.link.target : ''} className="submenus-link">
                        {item[list['labelSource']]}
                    </a>
                </li>);
            }
        });

        return result;
    }

    openCellMenu(key, child) {
        if (child || child === 0) {
            let keys = this.state.keys;
            keys['dropdown' + key + '-' + child] = !this.state.keys['dropdown' + key + '-' + child];
            this.setState({
                currentRowOpened: key,
                currentRowChildOpened: child,
                keys
            });
        }
        else {
            let keys = this.state.keys;
            keys['dropdown' + key] = !this.state['dropdown' + key];
            this.setState({
                currentRowOpened: key,
                currentRowChildOpened: false,
                keys
            });
        }
    }

    onCellActionTrigger(button) {
        const route = this.generateRoute(button.link);
        this.props.onCellActionTrigger(button, this.props.node, route);
    }

    handleLinkClick(e, route) {
        e.preventDefault();
        this.props.agGridReact.props.frameworkComponents.linksRendererParams.history.push(route);
    }

    openPopup(button) {
        this.props.agGridReact.props.frameworkComponents.linksRendererParams.openNewPopup(button.popup, this.props.value);
    }

    render() {
        var buttonsRender = this.props.buttons.map((button, key) => {
            if (button && 'checkPermissions' in button) {
                let stop = false;
                button.checkPermissions.map(item => {
                    if (!this.props.data[item]) {
                        stop = true;
                    }
                });
                if (stop) return;
            }
            if (button && 'children' in button) {
                return <span className="dropdown-grid" key={key}>
                    <a href="#" className="btn" onClick={() => this.openCellMenu(key)}>
                        <i className="fa fa-ellipsis-v" aria-hidden="true" style={{marginLeft: 10}}></i>
                    </a>
                    {
                        this.state.keys['dropdown' + key] &&
                        <nav className="submenus changeleft"
                             ref={this.setWrapperRef}>
                            <ul className="submenus-items">
                                {
                                    button.children.map((child, childKey) => {
                                        if ('checkPermissions' in child) {
                                            let stop = false;
                                            child.checkPermissions.map(item => {
                                                if (!this.props.data[item]) {
                                                    stop = true;
                                                }
                                            });
                                            if (stop) return;
                                        }
                                        if ('list' in child) {
                                            const childEls = this.getChildsFromList(child.list);
                                            return <li className="submenus-item" style={{marginLeft: 0}}
                                                       key={childKey}>
                                                <a href="#" className="submenus-link"
                                                   onClick={() => this.openCellMenu(key, childKey)}>
                                                    <Translate locale={this.props.settings.locale}
                                                               value={child.label}/>
                                                    <i className="fa fa-angle-down"
                                                       aria-hidden="true"></i>
                                                </a>
                                                {
                                                    this.state.keys['dropdown' + key + '-' + childKey] &&
                                                    <ul className="submenus-items ">
                                                        {childEls}
                                                    </ul>
                                                }
                                            </li>
                                        }
                                        else {
                                            if ('link' in child && !('redirect' in child && child.redirect === false)) {
                                                var route = this.generateRoute(child.link);
                                                return child.reactComponent ?
                                                    <li className="submenus-item"
                                                        style={{marginLeft: 0}}
                                                        key={childKey}>
                                                        <a href={route}
                                                              className="submenus-link"
                                                              key={key}>
                                                            {child.label}
                                                        </a>
                                                    </li> :
                                                    <li className="submenus-item"
                                                        style={{marginLeft: 0}}
                                                        key={childKey}>
                                                        <a href={route}
                                                           target={child.link.target ? child.link.target : ''}
                                                           className="submenus-link"
                                                           key={key}>
                                                            {child.label}
                                                        </a>
                                                    </li>
                                            }
                                            else {
                                                return <li className="submenus-item"
                                                           style={{marginLeft: 0}}
                                                           key={childKey}>
                                                    <a onClick={() => this.onCellActionTrigger(child)}
                                                       className="submenus-link" key={key}>
                                                        {child.label}
                                                    </a>
                                                </li>
                                            }
                                        }
                                    })
                                }
                            </ul>
                        </nav>
                    }
                    </span>
            }
            else {
                if (button && 'link' in button && !('redirect' in button && button.redirect === false)) {
                    var route = this.generateRoute(button.link);
                    return button.reactComponent ? 
                    <a href={route} title={button.title} className="marg" key={key} onClick={(e) => this.handleLinkClick(e, route)}>
                        <i className={button.icon} aria-hidden="true"></i>
                    </a> 
                    :
                    <a href={route} title={button.title} target={button.link.target ? button.link.target : ''}
                                 className="marg" key={key}>
                        <i className={button.icon} aria-hidden="true"></i>
                    </a>
                }
                else if('popup' in button){
                    return <a className="marg" key={key} onClick={() => this.openPopup(button)}>
                        <i className={button.icon} aria-hidden="true"></i>
                    </a>
                }
                else {
                    return <a onClick={() => this.onCellActionTrigger(button)} className="marg" key={key}>
                        <i className={button.icon} aria-hidden="true"></i>
                    </a>
                }
            }
        })
        return (
            <span>
                {
                    buttonsRender
                }
          </span>
          );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    gridProperties: state.gridProperties
});

const mapDispatchToProps = dispatch => ({
    onCellActionTrigger: (button, node, route) => dispatch(onCellActionTrigger(button, node, route)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomCellRenderer);
